$t: rgba(255,255,255,.5);

html {
  --color1: #b2faff;
  --color2: #5ce5e5;
  --color3: #39bfbf;
  --color4: #238c8c;
  --color5: #165955;
  --color6: #0f332e;
  --color7: #081a14;
  --color8: #040d09;
}

