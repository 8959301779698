// @import 'colors';

* {
  transition: 1s;
}

html {
  box-sizing: border-box;
}

.noclicks {
  pointer-events: none;
}

body {
  background-color: var(--color1);
  color: var(--color8);
  height: 100vh;
}

iframe {
  background-color: var(--color7);
  outline:  8px solid var(--color8);
  border: none;
  width: 100%;
  max-width: 800px;
  aspect-ratio:calc(1920/1080);
}

.playFrame {
  min-height: 175px;
  margin-bottom: 3em;
  aspect-ratio: 720/175;
}

.gameInfo {
  margin-bottom: 5em;
  background-color: var(--color7);
  box-shadow: 4px 4px var(--color6);
  padding: 16px;

  .gameTitle {
    color: var(--color1);
    font-size: 2em;
  }
  .gameDescription {
    color: var(--color2);
    font-size: 1.3em;
  }
}

.nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  
  a {
    padding: 8px;
    font-size: 2em;
    background-color: var(--color8);
    color: var(--color3);
    text-decoration: none;
    border-color: var(--color5);
    border: 2px solid;
    text-align: center;
    transition: .2s;
    letter-spacing: .2rem;
  }
  
  a:hover {
    background-color: var(--color6);
    color: var(--color2);
    border: 8px solid var(--color1);
    transition: .3s;
    letter-spacing: .8rem;
  }
  a:active {
    color: var(--color2);
  }
}

.playBtn {
  background-color: var(--color8);
  transition: .5s;

  a {
    color: var(--color3);
  }
}

.playBtn:hover {
  background-color: var(--color6);
  transition: .3s;
}

.App {
  z-index: 2;
  max-width: 800px;
  display: grid;
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 2em;
  padding-bottom: calc(2 * 6vw);
}

.animFromTop {
  animation-duration: 1s;
  animation-name: animFromTop;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

h1 {
  color: var(--color7); 
}

.decor {
  position: fixed;
  bottom: -20px;
  width: 100%;

  .grass {
    z-index: 1;
  }
  .mid {
    z-index: 9;
  }
  .hill {
    z-index: 6;
    display: none;
  }
}

.swatch {
  position: fixed;
  bottom: 16px;
  height: 32px;
  width: 32px;
  display: inline-block;
  z-index: 50;
  outline: 2px solid white;
  opacity: .3;
  transition: .5s;
  // background-color: black;

  &.s0 {
    background-color: #39bfbf;
    left: 16px
  }
  &.s1 {
    background-color: #b2d942;
    left: 64px;
  }
  &.s2 {
    background-color: #8d402f;
    left: calc(64px + 48px);
  }
  &.s3 {
    background-color: #8b7d62;
    left: calc(64px + 96px);
  }
  &.s4 {
    background-color: #f6cd26;
    left: calc(64px + 96px + 48px);
  }
}

.swatch:hover {
  transition: .6s;
  opacity: 1;
}

@keyframes animFromTop{
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@media (width <= 700px){
  * { transition: .05s; }
  
  body { font-size: .9rem; }

  .App { 
    max-width: 90%;
    min-width: 200px; }
  
}