// @import './colors';

@font-face {
  font-family: videotype;
  src: url(./videotype.ttf);
}

* {
  transition: .3s;
}

body, button {
  margin: 0;
  font-size: 1rem;
  font-family: videotype, sans-serif, monospace;
  -webkit-font-smoothing: antialiased;
  transition: .3s;
}


  

