@import 'colors';

.griddy {
  display: grid;
}

.griddy {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "info info" "artist artist" "developer other";
  // grid-template-rows: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 5em;
  }
  
  .info { grid-area: info; }
  .artist { grid-area: artist; }
  .developer { grid-area: developer; }
  .other { grid-area: other; }

  .gwid {
    background-color: var(--color6);
    color: var(--color1);
    border-radius: 2px;
    padding: 16px;
  }

  .topGrid {
    display: grid;
    grid-template-areas: "a a" "b c";
    padding: 24px;
    justify-items: center;
  }

  .pfpdiv {
    display: grid;
  }

  .pfp {
    margin-left:auto;
    margin-right: auto;
    margin-top: 24px;
    padding: 8px;
    width: 80%;
    max-width: 300px;
    border-radius: 50%;
    outline: 4px dashed var(--color4);
    filter: grayscale(.7);
  }

  .pfptxt {
    text-align: center;
  }

  @media (width <= 700px){
    * { transition: .3s; }

    .griddy {
      grid-template-columns: repeat(1,1fr);
      grid-template-areas: "info" "artist" "developer" "other";
      gap: 8px;
    }
  }